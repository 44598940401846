import React from 'react';

const TrimFunction = ({ description }) => {
  const trimDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + '.....';
    } else {
      return description;
    }
  };

  return <>{trimDescription(description, 60)}</>;
};

export default TrimFunction;
