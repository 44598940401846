import { useState, useEffect } from 'react';

const useRouteHook = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return { isLoaded };
};

export default useRouteHook;
