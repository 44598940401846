import { ReactComponent as Trivia } from '../../resources/images/trivia.svg';
import { ReactComponent as Scrabble } from '../../resources/images/scramble.svg';
import { ReactComponent as Connect4 } from '../../resources/images/connect4.svg';
import { ReactComponent as Tennis } from '../../resources/images/tennis-event.svg';
import { ReactComponent as Swimming } from '../../resources/images/swimming-event.svg';
import { ReactComponent as PES } from '../../resources/images/pes-events.svg';
import { ReactComponent as Instagram } from '../../resources/images/instagram.svg';
import { ReactComponent as Facebook } from '../../resources/images/facebook.svg';
import { ReactComponent as Twitter } from '../../resources/images/twitter.svg';
import { ReactComponent as KeyIcon } from '../../resources/images/key.svg';
import { ReactComponent as DollarIcon } from '../../resources/images/dollar.svg';
import { ReactComponent as PlayIcon } from '../../resources/images/playbtn.svg';
import { ReactComponent as BoxIcon } from '../../resources/images/box.svg';
import firstImg from '../../resources/images/first.jpeg';
import secondImg from '../../resources/images/second.jpeg';
import thirdImg from '../../resources/images/third.jpeg';
import fourthImg from '../../resources/images/fourth.jpeg';

export const trendGames = [
  {
    id: 1,
    icon: (
      <Trivia
        width="100%"
        height="auto"
      />
    ),
    matches: [],
  },
  {
    id: 2,
    icon: (
      <Scrabble
        width="100%"
        height="auto"
      />
    ),
    matches: [],
  },
  {
    id: 3,
    icon: (
      <Connect4
        width="100%"
        height="auto"
      />
    ),
    matches: [],
  },
];

const generateRandomPlayers = (numPlayers) => {
  const players = [];
  const playerNames = ['Alice', 'Bob', 'Charlie', 'David', 'Eve', 'Frank', 'Grace', 'Heidi', 'Ivan', 'Judy'];
  
  for (let i = 0; i < numPlayers; i++) {
    const player = {
      name: playerNames[Math.floor(Math.random() * playerNames.length)],
      score: Math.floor(Math.random() * 100)
    };
    players.push(player);
  }
  return players;
};

const simulateMatchesForGames = (games, numMatches = 5, numPlayersPerMatch = 2) => {
  return games.map(game => {
    const matches = [];
    for (let i = 0; i < numMatches; i++) {
      matches.push({
        matchId: i,
        players: generateRandomPlayers(numPlayersPerMatch)
      });
    }
    return { ...game, matches };
  });
};

// Simulate the data
export const simulatedTrendGames = simulateMatchesForGames(trendGames);



export const games = [
  {
    title: 'Apex Legends Tournament By Xbox',
    date: 'Jun 27 - Jun 29, 2020',
    participants: 90,
    images: (
      <Tennis
        width="100%"
        height="auto"
      />
    ),
  },
  {
    title: 'Apex Legends Tournament By Xbox',
    date: 'Jun 27 - Jun 29, 2020',
    participants: 24,
    images: (
      <Swimming
        width="100%"
        height="auto"
      />
    ),
  },
  {
    title: 'Apex Legends Tournament By Xbox',
    date: 'Jun 27 - Jun 29, 2020',
    participants: 64,
    images: (
      <PES
        width="100%"
        height="auto"
      />
    ),
  },
];

export const howItWorksData = [
  {
    icon: <KeyIcon />,
    title: 'Register',
    details: 'Register and login to enjoy games and win as you play.',
  },
  {
    icon: <DollarIcon />,
    title: 'Deposit',
    details: 'Deposit required entry fee in order to wager and win.',
  },
  {
    icon: <PlayIcon />,
    title: 'Demo or Play',
    details: 'Choose any game from our variety of games and play with friends.',
  },
  {
    icon: <BoxIcon />,
    title: 'Win',
    details: 'Play and win real money and either withdraw or purchase items.',
  },
];

export const socials = [
  {
    icon: <Instagram />,
    link: 'https://www.instagram.com/',
  },
  {
    icon: <Facebook />,
    link: 'https://www.facebook.com/',
  },
  {
    icon: <Twitter />,
    link: 'https://twitter.com/',
  },
];

export const links = [
  { to: '/games', text: 'Games' },
  { to: '/live-events', text: 'Live Events' },
  { to: '/news', text: 'News & Blog' },
];

export const companyLinks = [
  { to: '/about', text: 'About' },
  { to: '/careers', text: 'Careers' },
  { to: '/contact', text: 'Contact' },
];

export const images = [firstImg, secondImg, thirdImg, fourthImg];
export const imagePositions = [
  'top-[-8rem] left-[4rem]',
  'top-[-5rem] right-0',
  'top-[12rem] left-[16rem] border-4 border-white',
  'bottom-[-35rem] right-[27rem]  border-4 border-white',
];
