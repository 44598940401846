import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ShopCards } from './CardData';
import TrimFunction from '../TrimFunct/TrimFunction';
import { Link } from 'react-router-dom';
import { categories } from './ShopCategory';

const ShoppingCards = () => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const location = useLocation();

  return (
    <div className="py-6">
      <div className="flex justify-start mb-4 sm-max:flex sm-max:flex-col sm-max:gap-2">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`mr-2 px-4 py-2 rounded ${
              category === selectedCategory
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
        {ShopCards.filter((card) => card.category === selectedCategory).map(
          (card, index) => (
            <Link
              key={index}
              to={
                location.pathname.startsWith('/userdashboard/shop')
                  ? `product/${card.id}`
                  : '#'
              }
            >
              <div className="bg-white rounded-lg shadow-md">
                <div className="flex justify-center h-[15rem]">
                  <img
                    src={card.img}
                    alt="Product"
                    className="w-full h-full object-cover rounded-t-md cursor-pointer"
                  />
                </div>
                <div className="mt-1 px-2 py-3">
                  <h2 className="text-2xl font-bold">{card.title}</h2>
                  <p className="text-lg font-normal">
                    <TrimFunction description={card.description} />
                  </p>
                  <div className="flex items-start flex-col mt-2">
                    <div className="flex items-start justify-between w-full">
                      <div>
                        <p className="text-gray-600">₦{card.price}</p>
                      </div>
                      <div>
                        <p className="text-gray-400 line-through">
                          {card.crossedPrice}
                        </p>
                      </div>
                    </div>
                    <div className="text-red-600 mt-1 p-1 bg-[#FE020266] rounded-sm">
                      <p className="flex items-center justify-center">
                        {card.percent}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ),
        )}
      </div>
    </div>
  );
};

export default ShoppingCards;
