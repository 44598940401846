import { useLocation } from 'react-router';
import { data } from './Data';
import useSurvey from './useSurveyHook';

export const ComingSoon = () => {
  const { interests, checkPopVisible, xPopVisible, handleInterest } =
    useSurvey(data);
  const location = useLocation();
  const isDash = location.pathname.includes('/userdashboard');

  return (
    <div className="p-4 pt-0 mb-[2rem]">
      <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-3 gap-6 sm-max:flex sm-max:flex-col sm-max:items-center">
          {data.map((game, id) => (
            <div
              key={id}
              className="relative"
            >
              <div className="overflow-hidden rounded-lg">
                <div className="relative h-[450px] md-max:h-full md:w-full sm-max:w-full sm-max:h-full">
                  <div className="absolute inset-0 bg-white opacity-60 w-full h-full"></div>
                  {game.icon}
                </div>
                <button className="absolute top-8 left-2/4 transform -translate-x-2/4 bg-[#227EF3] lg:p-3 hover:bg-[#227EF399] px-2 py-1 text-white text-[16px] font-bold  rounded-lg w-[30%] z-20 sm-max:w-auto">
                  Coming soon
                </button>
              </div>
              <div className="flex items-start justify-between gap-2 bg-gray-100 rounded-b-lg py-4 px-2 md:text-sm">
                <h3 className="font-normal">
                  Are you interested in our games?
                </h3>
                <div className="flex items-center justify-center ">
                  <button
                    className={`mr-4 p-[2px] rounded-full border ${
                      interests[id] === false
                        ? 'bg-red-500 border-red-500 text-white'
                        : 'border-gray-300'
                    }`}
                    onClick={() => handleInterest(id, false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <button
                    className={`p-[2px] rounded-full border ${
                      interests[id] === true
                        ? 'bg-green-500 border-green-500 text-white'
                        : 'border-gray-300'
                    }`}
                    onClick={() => handleInterest(id, true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {checkPopVisible[id] && (
                <div className="bg-green-100 p-2 mt-2 rounded text-center">
                  Thanks for participating 😊
                </div>
              )}
              {xPopVisible[id] && (
                <div className="bg-red-100 p-2 mt-2 rounded text-center">
                  Ooops 😞 We still appreciate!!
                </div>
              )}
            </div>
          ))}
        </div>
        <p className="pt-8 sm-max:text-center">
          <span className="font-bold">Note:</span> Your survey will help us know
          which game to release and build
        </p>
      </div>
    </div>
  );
};
