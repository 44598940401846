import { ReactComponent as Cup } from '../../resources/images/cup.svg';
import { ReactComponent as Ball } from '../../resources/images/round-ball.svg';
import { useLocation } from 'react-router';
import '../../App.css';

export const BlueSection = () => {
  const urlLocation = useLocation();
  const isLive = urlLocation.pathname.includes('/live-events');
  const isShop = urlLocation.pathname.includes('/shop');
  return (
    <div className="bg-[#227EF3] relative w-full py-[7rem] rounded-md sm-max:py-[4rem]">
      <div className="absolute top-[-1rem] left-32 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 md-max:left-6 sm-max:top-[-45%] ">
        <Cup className="cup-svg" />
      </div>
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2">
        <h2 className="text-white text-[1.8rem] text-center sm-max:text-[16px]">
          {isLive
            ? '81 Live events are waiting for you 😊'
            : isShop
              ? '41 shop items are waiting for you 😊'
              : '81 game invites are waiting for you 😊'}
        </h2>
      </div>
      <div className="absolute top-[5rem] right-28 transform -translate-y-1/2 px-6 py-2 sm-max:top-[4rem] sm-max:right-[-.5rem] md-max:right-1">
        <Ball className="ball-svg" />
      </div>
    </div>
  );
};
