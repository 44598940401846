import React from 'react';
import Hero from '../../components/hero/Hero';
import TrendingGames from '../../components/trendingGames';
import LiveEvents from '../../components/liveEvents';
import HowItWorks from '../../components/howItWorks';
import MoreGames  from '../../components/MoreGames';
import ShopSection from '../../components/Cards/ShopSection';
import ScrollEffect from '../../components/Shared/ScrollEffect';

const Features = () => {
  return (
    <>
      <main>
        <div className="lg:px-[5.5rem]  md:px-10  sm-max:px-7">
          <Hero />
          <MoreGames />
          <TrendingGames />
          <LiveEvents />
          <ShopSection/>
          <HowItWorks />
        </div>
        <ScrollEffect/>
      </main>
    </>
  );
};

export default Features;
