import React from 'react';
import LiveEvents from '../../components/liveEvents';
import { BlueSection } from '../../components/Shared/BlueSection';
import { BlackSection } from './BlackSection';

const LiveEventsPage = () => {
  return (
    <div className="lg:mt-[3rem] lg:px-[5.5rem] md:px-10 sm-max:px-7 mb-12">
      <div className="">
        <p className="text-[32px] sm:text-[56px] font-bold">
          Join Live Events and win <br/> amazing prizes.
        </p>
        <p className="sm:text-[20px]">
          Compete with friends to win amazing prices by joining events like
          swimming, tennisand alot more!
        </p>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="w-full">
          <LiveEvents />
          <LiveEvents />
        </div>
        <div className="w-full flex items-center justify-center  sm:mt-16">
          <BlackSection />
        </div>
        <div className="w-full flex items-center justify-center mt-16">
          <BlueSection />
        </div>
      </div>
    </div>
  );
};

export default LiveEventsPage;
