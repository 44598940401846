import ShoppingCards from '../../components/Cards/ShoppingCards';
import { BlueSection } from '../../components/Shared/BlueSection';
const Shop = () => {
  return (
    <>
      <div className="
     lg:mt-[2rem] lg:px-[5.5rem] md:px-10 sm-max:px-7 mb-12">
        <div>
          <div className='pt-[1rem]'>
            <h1 className="text-[3rem] font-bold xsm-max:text-[2rem]">
              Explore Our Amazing <br /> Shop Items{' '}
            </h1>
            <p className='text-xl'>Win games and checkout  our amazing shop items on our platform.</p>
          </div>
        </div>

        <div>
          <ShoppingCards />
        </div>

        <BlueSection />
      </div>
    </>
  );
};


export default Shop;
