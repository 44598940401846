import React from 'react';
import '../../App.css';

const Contact = () => {
  return (
    <>
      <div className="lg:mt-[2rem] lg:px-[4rem] md:px-10 sm-max:px-7">
        <div className="flex items-start gap-[2rem] flex-col px-[1rem] pb-[4rem] md-max:pb-[1rem] sm-max:gap-[1rem] ">
          <h1 className="text-[3rem] font-bold xsm-max:text-[2rem]">
            Contact Us
          </h1>

          <div className="w-full flex items-start gap-3 md-max:flex md-max:flex-col">
            <div className="flex flex-col gap-4 text-[#333333] text-[22px] w-full sm-max:w">
              <p>
                Recheix is here to help you. <br /> Our experts are available to
                answer any questions you might have. We’ve got the
                answers.
              </p>

              <h2 className="uppercase font-normal text-3xl ">Visit Us</h2>
              <p className="underline">
                Novare Gateway Multipurpose center, space 101, Lugbe
                Abuja
              </p>
              <p>
                Feel free to get in touch with us through our channels:
              </p>
              <h2 className="uppercase font-normal text-3xl ">Email Us</h2>
              <span>Support@recheix.co</span>
              <h2 className="uppercase font-normal text-3xl ">Call Us</h2>
            </div>

            {/* <div className="relative w-full md-max:hidden sm-max:hidden">
              <div className="relative contact-img">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    className={`absolute ${imagePositions[index]} w-[256px] h-[467px]  object-cover`}
                    alt={`Image ${index + 1}`}
                  />
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="bg-[#1A191A] w-full mt-52 relative z-0">
        <div className="absolute top-[-6rem] left-[-3.8rem] text-[16rem] text-transparent custom-text-stroke font-bold z-10 sm-max:text-[5rem] sm-max:top-12 sm-max:left-[-1.5rem]">
          <div class="relative">
            <h1>contact</h1>
            <h1 className="small-text absolute top-[13rem] sm-max:top-[7rem]">
              us
            </h1>
          </div>
        </div>
        <div className="pt-[6rem] pb-[6rem] lg:px-[6.5rem] md:px-10 sm-max:px-7 flex flex-col md:flex-row items-start justify-between gap-[2rem] w-full">
          <div className="text-white text-left w-full md:w-auto z-50">
            <p className="font-bold text-[3rem] md-max:text-[2rem]">
              Have an Enquiry!
            </p>
            <p className="font-bold text-[3rem] md-max:text-[2rem] mb-4">
              Let's Discuss
            </p>
            <p className="font-normal text-xl mb-4">
              Thank You for getting in touch!
            </p>
            <p className="font-normal text-xl mb-4">
              Kindly, Fill in the form have a great day
            </p>
          </div>

          <div className="w-full md:w-[50%] relative z-50">
            <form className="flex flex-col items-start">
              <label
                htmlFor="name"
                className="text-white pb-1"
              >
                Your Name
              </label>
              <input
                required
                type="text"
                id="name"
                className="border-b border-white mb-12 bg-transparent text-white w-full md:w-[70%] sm:w-[70%] outline-none"
              />

              <label
                htmlFor="email"
                className="text-white pb-1"
              >
                Your Email
              </label>
              <input
                required
                type="email"
                id="email"
                className="border-b border-white w-full md:w-[70%] sm:w-[70%] outline-none mb-12 bg-transparent text-white"
              />

              <label
                htmlFor="phone"
                className="text-white pb-1"
              >
                Your Phone Number
              </label>
              <input
                required
                type="tel"
                id="phone"
                className="border-b border-white w-full md:w-[70%] sm:w-[70%] outline-none mb-12 bg-transparent text-white"
              />

              <label
                htmlFor="message"
                className="text-white pb-1"
              >
                Your Message
              </label>
              <input
                required
                type="text"
                id="message"
                className="border-b border-white w-full md:w-[70%] sm:w-[70%] outline-none mb-12 bg-transparent text-white"
              />

              <button
                type="submit"
                className="bg-[#227EF3] text-white py-2 px-4 rounded hover:bg-opacity-80"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
