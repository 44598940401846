import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Contact from './pages/Contact/Contact';
import Shop from './pages/Shop/Shop';
import Features from './pages/Features/Features';
import Games from './pages/Games/Games';
import Navbar from './components/Navbar';
import LiveEvents from './pages/LiveEvents/LiveEvents';
import Footer from './components/Footer/Footer';
import useRouteHook from './components/RouteHook/Hook';

function App() {
  const { isLoaded } = useRouteHook();


  return (
    <Router>
      {isLoaded && (
        <Navbar
        />
      )}
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/features" />}
        />
        {isLoaded && (
          <>
            <Route
              path="/features"
              element={<Features />}
            />
            <Route
              path="/games"
              element={<Games />}
            />
            <Route
              path="/live-events"
              element={<LiveEvents />}
            />
            <Route
              path="/shop"
              element={<Shop />}
            />
            <Route
              path="/contact"
              element={<Contact />}
            />
          </>
        )}
      </Routes>
      {isLoaded && <Footer />}
    </Router>
  );
}

export default App;
