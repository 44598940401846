import { useState, useRef } from 'react';

const useSurvey = (data) => {
  const [interests, setInterests] = useState(new Array(data.length).fill(null));
  const [checkPopVisible, setCheckPopVisible] = useState(
    new Array(data.length).fill(false),
  );
  const [xPopVisible, setXPopVisible] = useState(
    new Array(data.length).fill(false),
  );
  const timeoutRefs = useRef([]);

  const handleInterest = (index, interested) => {
    const newInterests = [...interests];
    newInterests[index] = interested;
    setInterests(newInterests);

    // Clearing previous timeout for this index
    if (timeoutRefs.current[index]) {
      clearTimeout(timeoutRefs.current[index]);
    }

    // Setting a new timeout to reset interests and pop-up visibility after 3 seconds
    timeoutRefs.current[index] = setTimeout(() => {
      const resetInterests = new Array(data.length).fill(null);
      setInterests(resetInterests);
      setCheckPopVisible(new Array(data.length).fill(false));
      setXPopVisible(new Array(data.length).fill(false));
    }, 3000);

    // Show pop-up based on the button clicked
    if (interested) {
      setCheckPopVisible((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
    } else {
      setXPopVisible((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
    }
  };

  return { interests, checkPopVisible, xPopVisible, handleInterest };
};

export default useSurvey;
