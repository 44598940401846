import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import navLogo from '../../resources/images/logo.png';
import Button from '../button/Button';
import { HiOutlineBars3 } from 'react-icons/hi2';
import { VscClose } from 'react-icons/vsc';
import { AiOutlineClose, AiOutlineSearch, AiFillBell } from 'react-icons/ai';
import useNavBar from './hooks/useNavbar';
import HalfMoonIcon from './Halfmoon';
import { GiShoppingCart } from 'react-icons/gi';
import { IoIosMenu } from 'react-icons/io';
import ShopCart from '../Cards/ShopCart';
import { Switch } from '@material-ui/core';

const Navbar = ({ toggleDarkMode }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartDisplay, setCartDisplay] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();
  const {
    location,
    toggleNavbar,
    closeNavbar,
    isNavbarOpen,
    isDashboard,
    isAuth,
    authenticate,
  } = useNavBar();

  const toggleCartDisplay = () => {
    setCartDisplay(!cartDisplay);
  };
  const toggleMenu = () => {
    console.log('Toggle menu');
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav
        className={` ${isAuth ? 'hidden' : 'block'} sticky top-0 left-0 right-0 bg-white z-50 shadow-md`}
      >
        <div
          className={`flex items-center justify-between py-4 ${isDashboard ? 'px-5' : 'lg:px-20 md:px-9 sm-max:px-5'}`}
        >
          <Link
            to="/features"
            className=""
          >
            <img
              src={navLogo}
              alt="recheix-games"
              className="w-[159px] h-[26px]"
            />
          </Link>

          <div
            className={`${isDashboard ? 'flex' : 'hidden'}  items-center justify-between gap-4 w-[75%]`}
          >
            <div className="flex items-center  gap-4 p-3 border-[1px] border-black rounded-full w-[30%] sm-max:w-full sm-max:p-1">
              <AiOutlineSearch className="w-6 h-6" />
              <input
                type="search"
                placeholder="Search"
                className="w-[80%] outline-none bg-white sm-max:w-1/2"
              />
            </div>
            <div
              className={`${menuOpen ? 'sm-max:flex' : 'sm-max:hidden'} sm-max:bg-white flex items-center justify-center gap-6 sm-max:absolute sm-max:top-16 sm-max:right-0  sm-max:-z-50 sm-max:flex sm-max:flex-col sm-max:justify-start sm-max:w-full sm-max:h-screen sm-max:shadow-sm transition-all duration-300 ease-in-out`}
            >
              <div className="p-3 rounded-xl border-[1px] border-black font-semibold sm:p-3 md:p-2 md-max:text-[12px] sm-max:w-1/2 sm-max:text-center sm-max:mt-10">
                <p>5000 NGN</p>
              </div>
              <div className="p-3 rounded-xl border-[1px] border-black font-semibold sm:p-3 md:p-2 md-max:text-[12px] sm-max:w-1/2 sm-max:text-center">
                <p>5.023 BTC</p>
              </div>
              <button className="bg-[#227EF3] p-3 rounded-xl text-white sm-max:w-1/2 sm-max:text-center">
                Deposit
              </button>
              <div className="h-11 w-11 rounded-full border-[1px] border-black flex items-center justify-center ">
                <AiFillBell className="h-6 w-6" />
              </div>
              <div
                className="p-2 border-[1px] border-black rounded-full cursor-pointer w-[45px] h-[45px] hover:border-[#227ef3] hover:text-[#227ef3]"
                onClick={toggleCartDisplay}
              >
                <GiShoppingCart className="w-full h-full" />
              </div>

              <div className="p-2 border-[1px] border-black rounded-full cursor-pointer">
                <HalfMoonIcon />
              </div>
            </div>
            <div
              className="text-2xl cursor-pointer sm-max:flex xl:hidden md:hidden"
              onClick={toggleMenu}
            >
              {menuOpen ? <VscClose /> : <IoIosMenu />}
            </div>
          </div>

          <div
            className={`${isDashboard ? 'hidden' : 'flex'} items-center justify-center gap-4"`}
          >
            <div
              id="menu-container"
              className={`flex items-center justify-center gap-8 sm-max:absolute sm-max:left-0 sm-max:right-0 sm-max:w-full sm-max:flex sm-max:flex-col sm-max:items-start sm-max:justify-start sm-max:p-7 sm-max:bg-[#eae9e9] ${isNavbarOpen ? 'top-[3.5rem]' : 'top-[-22rem]'} transition-all duration-300`}
            >
              <ul className="flex items-center justify-center gap-8 font-play font-bold text-[#1E1E1E] md-max:gap-3 sm-max:flex sm-max:flex-col sm-max:items-start sm-max:justify-start">
                <li>
                  <Link
                    to="/features"
                    className={`hover:text-[#227EF3] ${location.pathname === '/features' && 'text-[#227EF3]'}`}
                    onClick={closeNavbar}
                  >
                    Features
                  </Link>
                </li>
                <li>
                  <Link
                    to="/games"
                    className={`hover:text-[#227EF3] ${location.pathname === '/games' && 'text-[#227EF3]'}`}
                    onClick={closeNavbar}
                  >
                    Games
                  </Link>
                </li>
                <li>
                  <Link
                    to="/live-events"
                    className={`hover:text-[#227EF3] ${location.pathname === '/live-events' && 'text-[#227EF3]'}`}
                    onClick={closeNavbar}
                  >
                    Live Events
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shop"
                    className={`hover:text-[#227EF3] ${location.pathname === '/shop' && 'text-[#227EF3]'}`}
                    onClick={closeNavbar}
                  >
                    Shop
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className={`hover:text-[#227EF3] ${location.pathname === '/contact' && 'text-[#227EF3]'}`}
                    onClick={closeNavbar}
                  >
                    Contact
                  </Link>
                </li>
              </ul>

              <div>
                <Button
                to="https://app.reichex.co/authpage"
                  className="text-white bg-[#227EF3] px-8 py-2"
                >
                  Play and Win
                </Button>
              </div>
            </div>
            <div
              className="lg:hidden md:hidden bg-[#eae9e9] rounded p-1 cursor-pointer sm-max:block"
              onClick={toggleNavbar}
            >
              {isNavbarOpen ? (
                <AiOutlineClose className=" text-2xl text-[#1E1E1E] cursor-pointer " />
              ) : (
                <HiOutlineBars3 className=" text-2xl text-[#1E1E1E] cursor-pointer " />
              )}
            </div>
          </div>
        </div>
        {cartDisplay && <ShopCart cartItems={cartItems} />}
      </nav>
    </>
  );
};

export default Navbar;
