import Gamepad from '../../resources/images/controller.jpg';
import Home1 from '../../resources/images/home1.jpg';
import Home2 from '../../resources/images/home2.jpg';
import Home3 from '../../resources/images/home3.jpg';
import Home4 from '../../resources/images/home4.jpg';
import Home5 from '../../resources/images/home5.jpg';
import Home6 from '../../resources/images/home6.jpg';
import Phone1 from '../../resources/images/phone1.jpg';
import Phone2 from '../../resources/images/phone2.jpg';
import Phone3 from '../../resources/images/phone3.jpg';
import Phone4 from '../../resources/images/phone4.jpg';
import Phone5 from '../../resources/images/phone5.jpg';
import Phone6 from '../../resources/images/phone6.jpg';
import Diner1 from '../../resources/images/diner1.jpg';
import Diner2 from '../../resources/images/diner2.jpg';
import Diner3 from '../../resources/images/diner3.jpg';
import Chair1 from '../../resources/images/chair1.jpg';
import Chair2 from '../../resources/images/chair2.jpg';
import Chair3 from '../../resources/images/chair3.jpg';


export const ShopCards = [
  {
    id: 1,
    img: Home2,
    title: "Dining Set",
    description:
      'Immerse yourself in stunning 1080p visuals with wireless connectivity. Experience the convenience of 360-degree rotation and ambient lighting.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Home, Furniture & Appliances",
    images: [
      Diner1,
      Diner2,
      Diner3,
    ]
  },
  {
    id: 2,
    img: Phone1,
    title: "Samsung Phones",
    description:
      'Enhance your viewing experience with our advanced 1080p camera. Featuring wireless connectivity and 360-degree rotation for immersive visuals.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Phones & Tablets"
  },
  {
    id: 3,
    img: Home1,
    title: "Single Chair",
    description:
      'Discover the ultimate in visual technology with our 1080p wireless camera. Enjoy breathtaking 360-degree rotation and ambient lighting for an immersive experience.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Home, Furniture & Appliances",
    images: [
      Chair1,
      Chair2,
      Chair3,
    ]

  },
  {
    id: 4,
    img: Phone2,
    title: "Iphone Pouches",
    description:
      'Enhance your viewing experience with our advanced 1080p camera. Featuring wireless connectivity and 360-degree rotation for immersive visuals.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Phones & Tablets"
  },
  {
    id: 5,
    img: Home2,
    title: "Dining Set",
    description:
      'Immerse yourself in stunning 1080p visuals with wireless connectivity. Experience the convenience of 360-degree rotation and ambient lighting.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Home, Furniture & Appliances"
  },
  {
    id: 6,
    img: Phone3,
    description:
      'Enhance your viewing experience with our advanced 1080p camera. Featuring wireless connectivity and 360-degree rotation for immersive visuals.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Phones & Tablets"
  },
  {
    id: 7,
    img: Home3,
    description:
      'Upgrade your setup with our premium 1080p camera. Enjoy seamless wireless connectivity and experience immersive 360-degree rotation with ambient lighting.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Home, Furniture & Appliances"

  },
  {
    id: 8,
    img: Phone4,
    description:
      'Enhance your viewing experience with our advanced 1080p camera. Featuring wireless connectivity and 360-degree rotation for immersive visuals.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Phones & Tablets"
  },
  {
    id: 9,
    img: Home4,
    description:
      'Enhance your viewing experience with our advanced 1080p camera. Featuring wireless connectivity and 360-degree rotation for immersive visuals.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Home, Furniture & Appliances"

  },
  {
    id: 10,
    img: Phone5,
    description:
      'Enhance your viewing experience with our advanced 1080p camera. Featuring wireless connectivity and 360-degree rotation for immersive visuals.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Phones & Tablets"
  },
  {
    id: 11,
    img: Home5,
    description:
      'Discover the ultimate in visual technology with our 1080p wireless camera. Enjoy breathtaking 360-degree rotation and ambient lighting for an immersive experience.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Home, Furniture & Appliances"

  },
  {
    id: 12,
    img: Home6,
    description:
      'Immerse yourself in stunning 1080p visuals with wireless connectivity. Experience the convenience of 360-degree rotation and ambient lighting.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Home, Furniture & Appliances"

  },
  {
    id: 13,
    img: Phone6,
    description:
      'Enhance your viewing experience with our advanced 1080p camera. Featuring wireless connectivity and 360-degree rotation for immersive visuals.',
    price: 38000,
    crossedPrice: '₦5,000',
    percent: '-20%',
    category: "Phones & Tablets"
  },
];
