import { ReactComponent as Chess } from '../../resources/images/chess.svg';
import { ReactComponent as Ludo } from '../../resources/images/ludo.svg';
import { ReactComponent as Memory } from '../../resources/images/memory.svg';

export const data = [
  {
    id: 1,
    icon: (
      <Chess
        width="100%"
        height="auto"
      />
    ),
  },
  {
    id: 2,
    icon: (
      <Memory
        width="100%"
        height="auto"
      />
    ),
  },
  {
    id: 3,
    icon: (
      <Ludo
        width="100%"
        height="auto"
      />
    ),
  },
];
