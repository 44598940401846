import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

function useNavBar() {
  const location = useLocation();
  const isAuth = location.pathname.includes('/authpage')
  const isDashboard = location.pathname.includes('/userdashboard')
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
    const menuContainer = document.getElementById('menu-container');
    menuContainer.style.top = isNavbarOpen ? '-22rem' : '3.5rem';
  };


  const closeNavbar = () => {
    setIsNavbarOpen(false);
    const menuContainer = document.getElementById('menu-container');
    menuContainer.style.top = '-22rem';
  };

  return {
    isNavbarOpen,
    toggleNavbar,
    closeNavbar,
    location,
    isAuth,
    isDashboard
  };
}

export default useNavBar;
