import React, { useState, useEffect } from 'react';
import Button from '../button/Button';
import ThreeDAnime from './ThreeD';

const Hero = () => {
  const [typingIndex, setTypingIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [textColor, setTextColor] = useState('#227EF3');

  const textList = [
    'GAMING',
    'FUN',
    'SHOPPING',
    'EARNING'
  ];

  useEffect(() => {
    const currentText = textList[typingIndex];
    let currentIndex = 0;

    const typingEffect = () => {
      if (currentIndex <= currentText.length) {
        const coloredText = currentText.split('').map((char, index) => (
          <span key={index} style={{ color: getRandomColor() }}>{char}</span>
        ));
        setDisplayText(coloredText);
        currentIndex++;
        setTimeout(typingEffect, 200);
      } else {
        setTimeout(() => {
          setTypingIndex((typingIndex + 1) % textList.length);
        }, 2000); // Delay before starting typing the next text
      }
    };

    typingEffect();

    return () => clearTimeout(typingEffect);
  }, [typingIndex]);

  const getRandomColor = () => {
    const colors = ['#227EF3', '#34D399', '#FBBF24', '#F87171'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <>
      <div className="lg:mt-14 mb-16 grid grid-cols-2 items-center gap-10 md:gap-24 md-max:grid-cols-1 md-max:items-start md-max:justify-start sm-max:grid-cols-1 sm-max:items-start sm-max:gap-20">
        <div className="flex flex-col gap-2">
          <h1 className="sm:text-[56px] text-[32px] font-bold max-w-full">
            Elevate your {displayText} <br /> experience
          </h1>
          <h2 className="text-[#227EF3] font-bold text-[38px] sm-max:text-[26px]">
            To REICH new HEIGHTS
          </h2>
          <p className="text-[22px] leading-[35px] md-max:w-[90%]">
            We are building the first Nigerian esport and edutainment platform that allows over 60M game fans experience real gaming and stand a higher  chance of earning daily, weekly and monthly.
          </p>
          <div className="mt-8">
            <Button
              to="https://app.reichex.co/authpage"
              className="text-white bg-[#227EF3] px-6 py-4 hover:scale-115 transition-transform duration-500 ease"
            >
              Play and Win
            </Button>
          </div>
        </div>
 
        <div className="">
          <ThreeDAnime />
        </div>
      </div>
    </>
  );
};

export default Hero;
