import React from 'react';
import TrendingGames from '../../components/trendingGames';
import { ComingSoon } from '../../components/Shared/ComingSoon';
import { BlueSection } from '../../components/Shared/BlueSection';

const Games = () => {
  return (
    <div className="mt-[.5rem] lg:px-2 sm:p-12 flex flex-col justify-center items-center">
      <div className="w-[90%]">
        <p className=" sm:text-[56px] text-[32px] font-bold max-w-[320px]">
          Play Games and win
        </p>
        <p className="sm:text-[20px] mt-2">
          Choose from a wide options of games to compete and play with friends
        </p>
      </div>
      <div className="lg:mt-14 mt-11 w-full">
        <TrendingGames />
      </div>
      <div className="mt-11 sm:mt-16 w-[100%]">
        <ComingSoon />
      </div>
      <div className="w-[90%] flex items-center justify-center mt-11">
        <BlueSection />
      </div>
    </div>
  );
};

export default Games;
