import React from 'react';
import slide1 from '../../resources/images/slide1.jpg';
import slide2 from '../../resources/images/slide2.jpg';
import slide3 from '../../resources/images/slide3.jpg';
import slide4 from '../../resources/images/slide4.jpg';
import slide5 from '../../resources/images/slide5.jpg';
import './ThreeD.css';

const ThreeDAnime = () => {
  return (
    <div className='slider'>
      <span style={{ '--i': 1 }}><img src={slide1} alt='' /></span>
      <span style={{ '--i': 2 }}><img src={slide2} alt='' /></span>
      <span style={{ '--i': 3 }}><img src={slide3} alt='' /></span>
      <span style={{ '--i': 4 }}><img src={slide4} alt='' /></span>
      <span style={{ '--i': 5 }}><img src={slide5} alt='' /></span>
      <span style={{ '--i': 6 }}><img src={slide1} alt='' /></span>
      <span style={{ '--i': 7 }}><img src={slide2} alt='' /></span>
      <span style={{ '--i': 8 }}><img src={slide3} alt='' /></span>
      <span style={{ '--i': 9 }}><img src={slide4} alt='' /></span>
      <span style={{ '--i': 10 }}><img src={slide5} alt='' /></span>
    </div>
  );
};

export default ThreeDAnime;
