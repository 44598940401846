import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({
  children,
  to,
  className = '',
  padding = '',
  color = '[#227EF3]',
}) => {
  const buttonClass = `rounded ${padding} ${className} bg-${color} hover:bg-opacity-80`;

  if (to) {
    return (
      <Link
        to={to}
        className={buttonClass}
      >
        {children}
      </Link>
    );
  }

  return <button className={buttonClass}>{children}</button>;
};

export default Button;
