import React from 'react';
import { howItWorksData } from '../ConstantData/ConstantData';

const HowItWorks = () => {
  return (
    <div className="bg-[#fbfbfb] py-10 mb-20">
      <h3 className="text-center font-bold text-3xl pb-4 sm-max:text-2xl">
        How it works
      </h3>
      <p className="text-center text-2xl leading-12 pb-8">
        The ultimate gaming experience awaits. Join Reichex games today and
        embark <br />
        on a journey filled with excitement, competition, and endless
        possibilities.
      </p>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 px-6 pt-10 sm:px-12">
        {howItWorksData.map((item, index) => (
          <div
            key={index}
            className="flex items-start flex-col bg-white p-6 pb-16 rounded-lg"
          >
            <div className="bg-[#227EF3] w-12 h-12 flex items-center justify-center rounded-md">
              {item.icon}
            </div>
            <h4 className="text-lg font-bold mt-4">{item.title}</h4>
            <p className="mt-2">{item.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
