import React from 'react';

const ShopCart = ({ cartItems }) => {
  return (
    <div className="absolute top-16 right-[3rem] bg-white border border-gray-300 p-4 shadow rounded-md">
      <h2 className="text-lg font-semibold mb-4">Shopping Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <ul>
          {cartItems.map((item, index) => (
            <li key={index} className="flex justify-between items-center mb-2">
              <div>
                <p className="font-semibold">{item.product.title}</p>
                <p>Quantity: {item.quantity}</p>
              </div>
              <p>₦{item.product.price}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ShopCart;
