export const BlackSection = () => {
  return (
    <div className=" bg-black p-3 py-6 lg:p-6 w-full flex items-center justify-center rounded-md">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between  w-[70%] sm:w-full">
        <div className="flex items-center sm:justify-center gap-4 sm:border-r-[1.5px] p-2 md:p-8 sm:w-[30%]">
          <div
            className="h-11 w-11 sm:h-16 sm:w-16 rounded-full flex items-center justify-center bg-slate-50
        "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#227EF3"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 sm:w-11 sm:h-11 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
          </div>
          <p className="sm:max-w-[81px] text-[20px] sm:text-[25px]  text-white">
            300+ Gamers
          </p>
        </div>
        <div className="flex items-center sm:justify-center gap-4 sm:border-r-[1.5px] p-2 md:p-8 sm:w-[30%]">
          <div
            className="h-11 w-11 sm:h-16 sm:w-16 rounded-full flex items-center justify-center bg-slate-50
        "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#227EF3"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-8 w-8 sm:w-11 sm:h-11 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>
          </div>
          <p className="sm:max-w-[81px] sm:text-[25px] text-[20px] text-white">
            10+ Countries
          </p>
        </div>
        <div className="flex items-center sm:justify-center gap-4 p-2 lg:p-8 sm:w-[30%]">
          <div
            className=" h-11 sm:h-16 w-11 sm:w-16 rounded-full flex items-center justify-center bg-slate-50
        "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#227EF3"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-8 w-8 sm:w-11 sm:h-11 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.25 14.25h13.5m-13.5 0a3 3 0 0 1-3-3m3 3a3 3 0 1 0 0 6h13.5a3 3 0 1 0 0-6m-16.5-3a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3m-19.5 0a4.5 4.5 0 0 1 .9-2.7L5.737 5.1a3.375 3.375 0 0 1 2.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 0 1 .9 2.7m0 0a3 3 0 0 1-3 3m0 3h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Zm-3 6h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Z"
              />
            </svg>
          </div>
          <p className="sm:max-w-[81px] sm:text-[25px] text-[20px] text-white">
            5000+ Winners
          </p>
        </div>
      </div>
    </div>
  );
};
