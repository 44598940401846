import React from 'react';
import { games } from '../ConstantData/ConstantData';
import Xbox from '../../resources/images/xbox.png';
import Button from '../button/Button';
import { useLocation } from 'react-router';

const LiveEvents = () => {
  const urlLocation = useLocation();
  const isLive = urlLocation.pathname.includes('/live-events');

  // Function to calculate the percentage of participants
  const calculatePercentage = (participants, total) => {
    return (participants / total) * 100;
  };

  return (
    <div className="p-4 mb-[2rem]">
      <div className="max-w-screen-xl mx-auto">
        <h3
          className={`${isLive ? 'hidden' : 'block'} text-center font-bold text-3xl pb-4 pt-8 sm-max:text-2xl`}
        >
          Competition
        </h3>
        <p
          className={`${isLive ? 'hidden' : 'block'} text-center text-2xl leading-12 pb-2`}
        >
          Immerse yourself in the electrifying atmosphere of our Competitions!
          Participate in tournaments, <br /> challenges, and special events hosted regularly on{' '}
          <span className="text-[#227EF3] font-bold">Reichex games.</span>
        </p>
        <div className="grid grid-cols-3 gap-6 mt-[3rem] sm-max:flex sm-max:flex-col sm-max:items-center">
          {games.map((game, index) => (
            <div
              key={index}
              className="text-center"
            >
              <div className="flex items-start justify-start flex-col bg-[#d9d9d9] rounded-xl border-2 border-[#292932]">
                {game.images}
                <div className="flex items-start flex-col px-3 w-full">
                  <h6 className="font-bold mt-2">{game.title}</h6>
                  <p className="mt-1">{game.date}</p>

                  <div className="flex items-center justify-between w-full py-8">
                    <div className="flex items-center gap-2">
                      <div className="w-8 h-8">
                        <img
                          src={Xbox}
                          alt="xbox"
                          className="w-full h-full object-cover rounded-full"
                        />
                      </div>
                      <div className="w-[100px] md-max:w-[60px] flex justify-center items-center">
                        <div className="w-full bg-gray-400 rounded-full">
                          <div
                            style={{
                              width: `${calculatePercentage(game.participants, 100)}%`,
                              backgroundColor: '#227EF3',
                              height: '8px',
                              borderRadius: '4px',
                            }}
                          ></div>
                        </div>
                      </div>
                      {/* Displaying participants % */}
                      <p className="font-bold">
                        {calculatePercentage(game.participants, 100)}%
                      </p>
                    </div>
                    {/* Join button */}
                    <div>
                      <Button
                        to="/join-event"
                        className="text-white bg-[#227EF3] px-4 py-2"
                      >
                        Join
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`flex justify-center py-8 ${isLive ? 'hidden' : 'block'}`}
      >
        <Button
          to="/live-events"
          className="text-white bg-[#227EF3] px-8 py-3"
        >
          See more
        </Button>
      </div>
    </div>
  );
};

export default LiveEvents;
