import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const scrollThreshold = 200;

      // Show scroll-to-top button
      if (scrolled > scrollThreshold && !showScrollTop) {
        setShowScrollTop(true);
      } else if (scrolled <= scrollThreshold && showScrollTop) {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [showScrollTop]);

  const scrollToTop = () => {
    const scrollStep = -window.scrollY / 15; // Adjust scroll speed here
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15); // Adjust interval here for smoother or slower scrolling
  };

  const scrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {showScrollTop && (
        <div className="fixed bottom-4 right-4 z-50">
          <button
            onClick={scrollToTop}
            className="bg-blue-500 hover:bg-blue-600 text-white w-12 h-12 rounded-full shadow-md focus:outline-none flex justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </button>
        </div>
      )}

      {!showScrollTop && (
        <div className="fixed bottom-4 right-4 z-50">
          <button
            onClick={scrollDown}
            className="bg-blue-500 hover:bg-blue-600 text-white w-12 h-12 rounded-full shadow-md focus:outline-none flex justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default ScrollToTop;
