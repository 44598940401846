import React from 'react';
import { trendGames } from '../ConstantData/ConstantData';
import Button from '../button/Button';
import { useLocation, useNavigate } from 'react-router-dom';

const TrendingGames = ({
  bgColor = '[#fbfbfb]',
  padding = 'p-4',
  showTitle = true,
  showButton = true,
}) => {
  const urlLocation = useLocation();
  const isGames = urlLocation.pathname.includes("/games");
  const navigate = useNavigate();

  const redirectToDetails = (id) => {
    const game = trendGames.find((game) => game.id === id);
    if (game) {
      const { id, name, description } = game; 
      navigate(`/userdashboard/games/${id}`, { state: { id, name, description } });
    }
  };
  

  return (
    <div className={`bg-${bgColor} ${padding} mb-[2rem]`}>
      {showTitle && (
        <h3 className={`${isGames ? "hidden" : "block"} text-center font-bold text-3xl pb-14 pt-8 sm-max:text-2xl`}>
          Games
        </h3>
      )}
      <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-3 gap-6 sm-max:flex sm-max:flex-col sm-max:items-center">
          {trendGames.map((game) => (
            <div
              key={game.id}
              onClick={() => redirectToDetails(game.id)}
              className="text-center cursor-pointer"
            >
              {game.icon}
            </div>
          ))}
        </div>
      </div>

      {showButton && (
        <div className={`flex justify-center py-8 ${isGames ? "hidden" : "block"}`}>
          <Button
            to="/games"
            className="text-white bg-[#227EF3] px-8 py-3"
          >
            See more
          </Button>
        </div>
      )}
    </div>
  );
};

export default TrendingGames;
