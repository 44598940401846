import React from 'react';
import { Link } from 'react-router-dom';
import { ShopCards } from './CardData';
import Trim from '../TrimFunct/TrimFunction';
import Button from '../button/Button';

const ShopSection = () => {
  const limitedShopCards = ShopCards.slice(0, 12);

  return (
    <div className="p-4 mb-[2rem]">
      <h2 className="text-3xl font-bold mb-4 text-center">Explore Our Shop</h2>
      <p className="text-2xl mb-8 text-center mx-auto">
        Discover a curated collection of premium products for your home, tablets, computing needs, electronics, and sports.<br/> Immerse yourself in quality and innovation.
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-4">
        {window.innerWidth < 640 ? 
          limitedShopCards.slice(0, 3).map((card, index) => (
            <div key={index} className="bg-white rounded-lg shadow">
              <div className="flex justify-center">
                <img
                  src={card.img}
                  alt="Product"
                  className="w-full h-48 object-cover rounded-t-md"
                />
              </div>
              <div className="mt-4 p-4">
                <p className="text-lg font-normal"><Trim description={card.description} /></p>
                <div className="flex items-start flex-col mt-2">
                    <div className="flex items-start justify-between w-full">
                      <div>
                        <p className="text-gray-600">₦{card.price}</p>
                      </div>
                      <div>
                        <p className="text-gray-400 line-through">
                          {card.crossedPrice}
                        </p>
                      </div>
                    </div>
                    <div className="text-red-600 mt-1 p-1 bg-[#FE020266] rounded-sm">
                      <p className="flex items-center justify-center">
                        {card.percent}
                      </p>
                    </div>
                </div>
              </div>
            </div>
          )) :
          window.innerWidth < 1024 ?
          limitedShopCards.slice(0, 6).map((card, index) => (
            <div key={index} className="bg-white rounded-lg shadow">
              <div className="flex justify-center">
                <img
                  src={card.img}
                  alt="Product"
                  className="w-full h-48 object-cover rounded-t-md"
                />
              </div>
              <div className="mt-4 p-4">
                <p className="text-lg font-normal"><Trim description={card.description} /></p>
              </div>
            </div>
          )) :
          limitedShopCards.map((card, index) => (
            <div key={index} className="bg-white rounded-lg shadow">
              <div className="flex justify-center">
                <img
                  src={card.img}
                  alt="Product"
                  className="w-full h-48 object-cover rounded-t-md"
                />
              </div>
              <div className="mt-4 p-4">
                <p className="text-lg font-normal"><Trim description={card.description} /></p>
                <div className="flex items-start flex-col mt-2">
                    <div className="flex items-start justify-between w-full">
                      <div>
                        <p className="text-gray-600">₦{card.price}</p>
                      </div>
                      <div>
                        <p className="text-gray-400 line-through">
                          {card.crossedPrice}
                        </p>
                      </div>
                    </div>
                    <div className="text-red-600 mt-1 p-1 bg-[#FE020266] rounded-sm">
                      <p className="flex items-center justify-center">
                        {card.percent}
                      </p>
                    </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <div className="flex justify-center mt-8 mb-8">
        <Link to="/shop">
        <Button
          className="text-white bg-[#227EF3] px-8 py-3"
        >
          See more
        </Button>
        </Link>
      </div>
    </div>
  );
};

export default ShopSection;
